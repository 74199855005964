import {useEffect, useState} from 'react'
import {GetShippingHistoryResponse, ShippingStatusType} from './apiTypes'
import { fetcher } from '../services/http.service'
import {format} from "date-fns";
import useSWR from "swr";

const MAX_SIZE = 100;
export function useShippingHistories(tracking_number: string, t?: any) {
  const [result, setResult] = useState<{histories: ShippingStatusType[], formattedDate: string}>({formattedDate: '', histories: []});
  // const getKey = (pageIndex: number, previousPageData: GetShippingHistoryResponse) => {
  //   if (pageIndex === 0) return `
  //   if (previousPageData && previousPageData.next) return previousPageData.next // reached the end
  //   return null
  // }

  const { data, error, isValidating, mutate } = useSWR<GetShippingHistoryResponse>(
      `/shipping/shippingitems/${tracking_number}/history/?page_size=${MAX_SIZE}`,
      fetcher,
      { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  )
  function getDate(histories: ShippingStatusType[]) {
    let formatted = ''
    console.log('weekday', histories)
    if (histories && histories.length > 0) {
      try {
        const lastStatus = histories[histories.length - 1]
        const date = new Date(lastStatus.timestamp)
        const weekDay = t(format(date, 'EEE'))
        const time = format(date, 'HH:mm')
        const day = format(date, 'MM월 dd일')
        console.log('lastStatus weekday', lastStatus, format(date, 'EEE'), t(weekDay), histories)
        formatted = `${day} (${weekDay}) ${time}`
      } catch (error) {
        console.log('weekday format error', error)
      }
    }
    return formatted
  }


  console.log("histories", data, result)
  useEffect(() => {
    if (data) {
        const histories = data.results;
        const formattedDate = getDate(histories);
        setResult({histories, formattedDate});
    }
  }, [data])

  console.log('deliveries', result)
  return {
    result,
    isLoading: isValidating,
    mutate,
    isError: error,
  }
}
