import React, {useMemo, useState} from 'react'
import './tracking.style.css'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import ArrowDown from '../../../assets/svgs/ArrowDown'
import {Button, Skeleton} from 'antd'
import {GetShippingInfoResponse} from '../../../api/apiTypes'
import {callLink, DAASPHONE} from '../../../common/consts.common'
import PhoneSvg from '../../../assets/svgs/Phone'
import ShippingStatusItem from '../../organism/shippingItem/ShippingStatusItem'
import {useShippingHistories} from '../../../api/useShippingHistories'

const TrackingComponent = ({
                               tracking,
                               shippingInfo,
                               onReturn,
                           }: {
    tracking: string
    shippingInfo?: GetShippingInfoResponse
    onReturn: () => void
}) => {
    const {t} = useTranslation(['tracking'])
    const [active, setActive] = useState(false)
    const {result} = useShippingHistories(tracking, t)

    const statusNode = (
        <div className={'round-cntr column align-center'}>
            <div className={'tracking-sts body bold white'}>{t(shippingInfo?.status || '-')}</div>
            {!shippingInfo ? (
                <Skeleton title={false} paragraph={{rows: 2, className: 'column align-center'}}/>
            ) : (
                <div className={'column align-center'}>
                    <div className={'tracking-sts-lng subtitle bold primary'}>
                        {t('status.' + shippingInfo?.status, {
                            hub: shippingInfo?.hub_name || 'hub',
                            unit: shippingInfo?.unit_name || "unit",
                            shop: shippingInfo?.shop_name || "shop",
                        })}
                    </div>
                    <div
                        className={'tracking-info small medium black45'}>{t('status.sub.' + shippingInfo?.status)}</div>
                </div>
            )}
        </div>
    )

    const Panel = (
        <div className={'column ' + (active ? 'tracking-panel-active' : 'tracking-panel')}>
            <div className={'row'}>
                <div className={'tracking-label body bold black85'}>{t('pickup.date')}</div>
                <div className={'tracking-val body medium black45'}>{result?.formattedDate}</div>
            </div>
            <div className={'row'}>
                <div className={'tracking-label body bold black85'}>{t('sender.name')}</div>
                <div className={'tracking-val body medium black45'}>{shippingInfo?.shop_name}</div>
            </div>
            <div className={'row'}>
                <div className={'tracking-label body bold black85'}>{t('sender.addr')}</div>
                <div className={'tracking-val body medium black45'}>{shippingInfo?.shop_address}</div>
            </div>
        </div>
    )

    const productNode = (
        <div className={'column'}>
            <span className={'tracking-title body1 bold black85'}>{t('product.title')}</span>
            <div className={'round-cntr'}>
                <div className={'row'}>
                    <div className={'tracking-label body bold black85'}>{t('tracking.number')}</div>
                    <div className={'tracking-val body medium black45'}>{tracking}</div>
                </div>
                <div className={'row'}>
                    <div className={'tracking-label body bold black85'}>{t('receiver')}</div>
                    <div className={'tracking-val body medium black45'}>{shippingInfo?.receiver_name}</div>
                </div>
                <div className={'row'}>
                    <div className={'tracking-label body bold black85'}>{t('destination')}</div>
                    <div className={'tracking-val body medium black45'}>{shippingInfo?.receiver_address}</div>
                </div>
                <div className={'row'}>
                    <div className={'tracking-label body bold black85'}>{t('product.name')}</div>
                    <div className={'tracking-val body medium black45'}>{shippingInfo?.product_info}</div>
                </div>
                {Panel}
                <Button className={'tracking-btn-detail'} data-cy={'collapse'} onClick={() => setActive(act => !act)}>
                    <span className={'tracking-btn-txt body medium black45'}>{t('btn.detail')}</span>
                    <ArrowDown rotate={active ? 180 : 0}/>
                </Button>
            </div>
        </div>
    )

    const deliveryNode = (
        <div className={'column'}>
            <span className={'tracking-title body1 bold black85'}>{t('delivery.title')}</span>
            <div className={'round-cntr'}>
                <div className={'row'}>
                    <div className={'tracking-del-date body bold black85'}>{t('delivery.detail')}</div>
                    <div className={'tracking-del-sts body bold black85'}>{t('delivery.status')}</div>
                </div>
                {!!result.histories &&
                    result.histories.map((s, index) => (
                        <ShippingStatusItem
                            key={'sitem' + index}
                            {...s}
                            photo={shippingInfo?.photo}
                            last={index === result.histories.length - 1}
                        />
                    ))}
            </div>
        </div>
    )

    const daasNode = (
        <div className={'column'}>
            <span className={'tracking-title body1 bold black85'}>{t('daas.title')}</span>
            <div className={'round-cntr'}>
                <div className={'row'}>
                    <div className={'tracking-label body bold black85'}>{t('daas.company')}</div>
                    <div className={'tracking-val'}>
                        <div className={'body medium black45 flex'}>{t('daas.name')}</div>
                        <Button data-cy={'callLink'} shape={'circle'} className={'tracking-btn'}
                                href={callLink(DAASPHONE)}>
                            <PhoneSvg/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className={'tracking-container'}>
            {statusNode}
            {productNode}
            {deliveryNode}
            {daasNode}
            {shippingInfo?.allow_return && !shippingInfo?.is_return && shippingInfo?.status === 'DELIVERYCOMPLETE' && (
                <Button type={'primary'} className={'tracking-return-btn'} onClick={onReturn}>
                    {t('Apply returning')}
                </Button>
            )}
        </div>
    )
}

export default TrackingComponent
